import Theme from "theme";
import React, { useState, useEffect } from "react";

import { ShowPage } from "context/showPage";

import Switch from "components/web/switch";
import InputNumber from "components/web/inputNumber";
import Button from "components/web/button";

import api from "service";

export default (props) => {
  const { PageInfo } = ShowPage();

  const [copySchedule, setCopySchedule] = useState({});

  const [itemSelected, setItemSelected] = useState([]);

  let edit = props?.edit;

  let idCategory = props?.idCategory;

  useEffect(async () => {
    setCopySchedule(props.scheduleList);
  }, [props.scheduleList]);

  useEffect(async () => {
    setItemSelected([]);
  }, [PageInfo.show]);

  return {
    component: "container",
    style: {
      display: "flex",
      width: "100%",
      height: "auto",
    },
    children: [
      //item
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          height: "auto",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: 24,
          flexDirection: "column",
          ...props?.style?.box,
        },
        children: Object.values(copySchedule).map((item, index) => {
          // console.log(item);
          return {
            component: "container",
            style: {
              display: "flex",
              width: "40%",
              height: "100%",
              alignItems: "center",
              justifyContent: "flex-start",
              borderRadius: 8,
              flexDirection: "column",
              border: "1px solid #ECECEC",
            },
            children: [
              //icon + title
              {
                component: "container",
                style: {
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  flexDirection: "row",
                  padding: 8,
                  gap: 4,
                },
                children: [
                  Switch({
                    style: {
                      height: 24,
                    },
                    checked: copySchedule[item.category]?.enable,
                    value: copySchedule[item.category]?.enable,
                    onChange: async () => {},
                  }),
                  //titleContent
                  {
                    component: "text",
                    text: item?.name,
                    style: {
                      fontSize: 14,
                      marginTop: 8,
                      width: "100%",
                      color:
                        copySchedule[item.category]?.enable === false
                          ? "gray"
                          : "black",
                      fontFamily: "Poppins",
                      marginBottom: 8,
                    },
                  },
                ],
              },
            ],
          };
        }),
      },
    ],
  };
};
