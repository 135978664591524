import { privateHttp, publicHttp, baseURL } from "./http";
// import { listen } from "./sock";

let token = localStorage.getItem("token");

let headers = {
  headers: {
    Authorization: "Bearer " + token || "Error",
  },
};

const objectToQueryString = (obj, prefix = "") => {
  const pairs = [];

  for (const key in obj) {
    if (!obj.hasOwnProperty(key)) continue;

    const value = obj[key];
    const fullKey = prefix ? `${prefix}[${key}]` : key;

    // Se o valor for um objeto (mas não uma instância de Array), recursão é aplicada
    if (typeof value === "object" && !Array.isArray(value)) {
      pairs.push(...objectToQueryString(value, fullKey));
    } else if (Array.isArray(value)) {
      // Trata arrays, incluindo arrays de objetos
      value.forEach((val, i) => {
        if (typeof val === "object") {
          pairs.push(...objectToQueryString(val, `${fullKey}[${i}]`));
        } else {
          pairs.push(
            `${encodeURIComponent(fullKey)}[]=${encodeURIComponent(val)}`
          );
        }
      });
    } else {
      // Para valores não-objetos (ex., string, número)
      pairs.push(`${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`);
    }
  }

  return pairs.join("&");
};

const request = {
  get: async (path) => {
    try {
      if (token && token !== null && token !== "null" && token.length > 100) {
        headers.headers.Authorization = "Bearer " + token;
      } else {
        token = localStorage.getItem("token");
        if (!token || token.length < 100) return {};
        headers.headers.Authorization = "Bearer " + token;
      }

      return await privateHttp
        .get(path, headers)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err;
        });
    } catch (error) {
      return error;
    }
  },
  post: async (path, body) => {
    try {
      if (token && token !== null && token !== "null" && token.length > 100) {
        headers.headers.Authorization = "Bearer " + token;
      } else {
        token = localStorage.getItem("token");
        if (!token || token.length < 100) return {};
        headers.headers.Authorization = "Bearer " + token;
      }
      return await privateHttp
        .post(path, body, headers)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          // console.log("VerError", err.response.data);
          if (err.response) return err.response.data;
          return err;
        });
    } catch (error) {
      return error;
    }
  },
  public: {
    get: async (path) => {
      try {
        return await publicHttp
          .get(path)
          .then((res) => {
            return res;
          })
          .catch((err) => {
            return err;
          });
      } catch (error) {
        return error;
      }
    },
    post: async (path, body) => {
      try {
        return await publicHttp
          .post(path, body)
          .then((res) => {
            return res;
          })
          .catch((err) => {
            return err;
          });
      } catch (error) {
        return error;
      }
    },
  },
};

export default {
  // reescreva  as request utilizando a função request
  auth: {
    login: async (body) => request.public.post("/auth/signin", body),
  },
  people: {
    add: async (body) => request.public.post("/add_people", body),
    import: async (body) => request.post("/register_people", body),
    marketing: ({ body, query }) =>
      request.get("/get_user_stats?" + objectToQueryString(query)),
    listAll: ({ body, query }) =>
      request.get("/get_all_user?" + objectToQueryString(query)),
  },
  download: {
    sheetModel: async (body) =>
      window.open(baseURL + "/media/doc/sheet/" + body),
  },
  business: {
    add: async (body) => request.post("/business", body),
    list: ({ body, query }) =>
      request.get("/get_business_list?" + objectToQueryString(query)),
  },
  provider: {
    add: async (body) => request.post("/provider", body),
    list: ({ body, query }) =>
      request.get("/get_provider_list?" + objectToQueryString(query)),
    show: async (body) => request.post("/set_provider_show", body),
    categoryHour: async (id, date, provider) =>
      request.get(
        "/provider_category_schedule?id=" +
          id +
          "&date=" +
          date +
          "&provider=" +
          provider
      ),
    suggestTime: async (body) => request.post("/suggested_time", body),
    attProvider: async (body) =>
      request.post("/provider_delivery_service", body),
    categorySchedule: async (body) =>
      request.post("/provider_category_schedule", body),
  },
  employee: {
    add: async (body) => request.post("/people", body),
    list: ({ body, query }) =>
      request.get("/get_business_employee?" + objectToQueryString(query)),
    block: async (body) => request.post("/employee_block", body),
  },
  marketing: {
    notify: (body) => request.post("/marketing_pushup", body),
    pushup: ({ body, query }) =>
      request.get("/get_marketing_pushup_list?" + objectToQueryString(query)),
  },
  home: {
    get: () => request.get("/get_home"),
  },
  order: {
    list: ({ body, query }) =>
      request.get("/get_order_list?" + objectToQueryString(query)),
    updateStatus: () => request.get("/order_update_status"),
    confirm: (body) => request.post("/confirm_order_pending", body),
    cancel: (body) => request.post("/cancel_order", body),
    finalizeService: (body) => request.post("finalize_service", body),
    petDelivered: (body) => request.post("/pet_delivered", body),
    finalizeOrder: (body) => request.post("finalize_order", body),
    addNewService: (body) => request.post("/order_service_update", body),
  },
  utils: {
    animal_category: () => request.get("/animal_category"),
    service_category: () => request.get("/service_category"),
    category_promotion: (body) =>
      request.post("/provider_category_promotion", body),
    state_name: () => request.get("/state_name"),
    marketing_situation: () => request.get("/marketing_situation"),
    city_name: ({ body, query }) =>
      request.get("/city_name?" + objectToQueryString(query)),
    bank: () => request.get("/bank"),
    pix_type: () => request.get("/pix_type"),
    update: (body) => request.post("/update_info", body),
    unlinkPeople: (body) => request.post("/unlink_people", body),
    getUser: ({ body, query }) =>
      request.get("/get_user?" + objectToQueryString(query)),
    addressCode: ({ body, query }) =>
      request.get("/search_address_code?" + objectToQueryString(query)),
    clientCode: ({ body, query }) =>
      request.get("/search_client_code?" + objectToQueryString(query)),
  },
  financial: {
    list: ({ body, query }) =>
      request.get("/get_order_pay_list?" + objectToQueryString(query)),
    indicators: (body) => request.post("/financial_indicator", body),
    summary: ({ body, query }) =>
      request.get("/financial_resum?" + objectToQueryString(query)),
  },
  user: {
    login: async (body) => request.public.post("/auth", body),
    register: async (body) => request.public.post("/register", body),
    info: async ({ body, query }) =>
      request.public.get("/user_info?" + objectToQueryString(query)),
    save: async (body) => request.public.post("/register", body),
  },
  request,
  // fazer funcao que executa stream com socket
  listen: async ({ action, info, exec, opt }) => {},
};
