import { Render } from "@8_dev/sjson-web";
import Theme from "theme";
import { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import ContentTopBar from "components/web/ContentTopBar";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";
import getElement from "components/web/view/getElement";
import { parse } from "dotenv";

import LoadingGif from "assets/images/loading.gif";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const getNestedValue = (obj, keys) => {
  let currentObj = {};
  for (const key of keys) {
    if (!obj || !obj.hasOwnProperty(key)) {
      return undefined; // Chave não encontrada
    }
    currentObj = obj[key];
  }
  return currentObj;
};

const setNestedValue = (obj, keys, value) => {
  const [currentKey, ...remainingKeys] = keys;
  if (remainingKeys.length === 0) {
    obj[currentKey] = value;
  } else {
    obj[currentKey] = obj[currentKey] || {};
    setNestedValue(obj[currentKey], remainingKeys, value);
  }
};

export default (props) => {
  // Defina seu estado e lógica de controle aqui, se necessário
  const { PageInfo, GoBack } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  const [requestData, setRequestData] = useState({});
  const [clear, setClear] = useState(false);
  // let requestData = {};
  const [pageInfo, setPageInfo] = useState({});

  useEffect(() => {
    if (PageInfo?.param?.info) {
      setRequestData(PageInfo.param.info);
    } else setRequestData({});
  }, [PageInfo]);

  const { style, path, form, formData, direction } = props;

  const popupShow = props?.showPopup || true;

  const setPopup = PopupShow;

  if (popupShow === undefined) popupShow = true;

  let checkList = [
    {
      id: "",
      type: "",
    },
  ];
  const lockClientBtn = false;

  const cols = Object.keys(form.box);
  const setInput = (item) => {
    if (item.children) {
      item.children.map((child) => {
        child = setInput(child);
      });
    } else {
      if (!item.style) {
        if (item.keyid?.includes(".")) {
          const ks = item.keyid
            .split(".")
            .map((key) => (isNaN(key) ? key : Number(key)));
          setNestedValue(requestData, ks, item.value);
        } else {
          requestData[item.id] = item.value;
        }
        return { component: "text", style: { display: "none" } };
      }

      item.props = {};

      if (item.value) {
        if (item.keyid?.includes(".")) {
          const ks = item.keyid
            .split(".")
            .map((key) => (isNaN(key) ? key : Number(key)));
          setNestedValue(requestData, ks, item.value);
          item.props.value = item.value;
        } else {
          requestData[item.id] = item.value;
          item.props.value = item.value;
        }
      }
      item.value = item.props?.value || requestData[item.id];

      //set
      item.props.set = async ({ k, v }) => {
        try {
          if (!k || k === undefined) return;
          item.props.value = v;

          if (item.format) {
            if (item.format === "number") v = parseInt(v);
            // else v = "";
          }
          // setNestedValue(requestData, k.split("."), v);

          if (k.includes(".")) {
            const [fKey, ...objKey] = k.split(".");

            if (objKey.length === 0) {
              setRequestData({ ...requestData, [objKey]: v });
              return;
            }

            const lKey = objKey.pop();
            const ffk = objKey.shift();

            let itemValue = requestData;

            // console.log(itemValue, !itemValue, !itemValue[fKey]);
            if (!itemValue) itemValue = { [fKey]: isNaN(ffk) ? {} : [] };
            else if (!itemValue[fKey]) itemValue[fKey] = isNaN(ffk) ? {} : [];

            itemValue = itemValue[fKey];

            // console.log(itemValue);
            // console.log(fKey, ffk, objKey, lKey);

            if (ffk !== undefined) {
              if (itemValue === undefined) itemValue = { [ffk]: {} };
              else if (!itemValue[ffk]) itemValue[ffk] = {};

              itemValue = itemValue[ffk];
            }
            // console.log(itemValue, fKey, ffk, lKey);

            objKey.map((objk) => {
              if (itemValue[objk] === undefined) itemValue[objk] = {};
              itemValue = itemValue[objk];
            });

            if (itemValue === undefined) itemValue = { [lKey]: v };
            else itemValue[lKey] = v;
            // console.log(itemValue, requestData);

            // setRequestData({ ...requestData });
          } else {
            setRequestData({
              ...requestData,
              [k]: v,
            });
          }
        } catch (err) {
          console.log("eee", err);
        }
      };

      item.clear = clear;

      // para deletar uma posicao
      item.props.delete = async (id) => {
        const newList = [];
        requestData[item.id].map((item, index) => {
          if (index !== id) newList.push({ ...item, id: newList.length });
        });

        requestData[item.id] = newList;

        setRequestData({ ...requestData });
      };
    }
    return item;
  };
  const inputElements = cols.map((col) => {
    const { title, style, input } = form.box[col];

    return {
      component: "container",
      style: {
        flex: 1,
        ...style,
      },
      children: input.map((item) => {
        if (item.type === "div" || item.type === "container") {
          if (item.children)
            item.children.map((child) => {
              child = setInput(child);
            });
        } else if (item.id) item = setInput(item);

        return getElement(item);
      }),
    };
  });

  const [loadingView, setLoadingView] = useState(false);

  const stopLoading = async (time) => {
    await delay(time || 2000);
    setLoadingView(false);
  };

  return {
    render: (
      <div
        style={{
          overflow: "hidden",
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          maxHeight: "100%",
          zIndex: 3,
        }}
      >
        <Fade fade cascade>
          {Render({
            component: "container",
            style: {
              width: "100%",
              height: "100%",
              padding: "1%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              ...style,
            },
            children: [
              // loading
              {
                component: "container",
                style: {
                  top: 0,
                  left: 0,
                  width: "100%",
                  minWidth: "100%",
                  height: "100%",
                  maxHeight: "100%",
                  position: "absolute",
                  backgroundColor: Theme.colors.gray.background,
                  display: loadingView ? "flex" : "none",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 999,
                },
                children: [
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 999,
                      gap: -16,
                    },
                    children: [
                      {
                        render: (
                          <img
                            style={{
                              display: "flex",
                              aspectRatio: "1/1",
                              width: "10%",
                            }}
                            src={LoadingGif}
                          />
                        ),
                      },
                      {
                        render: (
                          <img
                            style={{
                              display: "flex",
                              aspectRatio: "1/1",
                              width: "10%",
                            }}
                            src={LoadingGif}
                          />
                        ),
                      },
                      {
                        render: (
                          <img
                            style={{
                              display: "flex",
                              aspectRatio: "1/1",
                              width: "10%",
                            }}
                            src={LoadingGif}
                          />
                        ),
                      },
                    ],
                  },
                ],
              },
              ContentTopBar({
                title: PageInfo?.title || props.title || "Form",
                src: props?.srcTopBar,
              }),
              {
                component: "container",
                style: {
                  flex: 1,
                  marginTop: "2%",
                  display: "flex",
                  flexWrap: "nowrap",
                  flexDirection: direction || "row",
                  width: "100%",
                  height: "98%",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  maxHeight: "90%",
                  overflow: "auto",
                  ...form.style?.main,
                },
                children: inputElements,
              },
              //btns
              {
                component: "container",
                style: {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  ...form?.style?.btn?.style,
                },
                children: [
                  //cancel
                  {
                    component: "container",
                    style: {
                      padding: 8,
                      paddingLeft: 16,
                      paddingRight: 16,
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      marginBottom: 24,
                    },
                    onClick: async () => {
                      setPopup({
                        title: "Cancelar",
                        description: "Você tem certeza ?",
                        cancelText: "Não",
                        confirmText: "Sim",
                        onConfirm: () => {
                          if (form.onCancel) {
                            form.onCancel();
                            GoBack();
                          } else {
                            setRequestData({});
                            setClear(!clear);
                            GoBack();
                          }
                        },
                      });
                    },
                    children: [
                      {
                        component: "text",
                        text: "Cancelar",
                        style: {
                          ...Theme.text.button,
                          fontSize: 14,
                          fontFamily: "Poppins",
                          color: Theme.colors.red.light,
                        },
                      },
                    ],
                  },
                  // save
                  {
                    component: "container",
                    style: {
                      ...Theme.button.action,
                      backgroundColor: Theme.colors.blue.life,
                      marginLeft: 16,
                      marginBottom: 24,
                      // backgroundColor: lockClientBtn
                    },
                    onClick: async () => {
                      if (lockClientBtn) return;
                      if (form.onSave) {
                        // return 1;
                        try {
                          setLoadingView(true);
                          // stopLoading();
                          let reqRes = {};
                          try {
                            reqRes = await form.onSave(formData || requestData);
                          } catch (error) {
                            setLoadingView(false);
                            setRequestData({});
                          }
                          if ((reqRes?.data || reqRes?.error) && form.func) {
                            try {
                              await delay(500);
                              setLoadingView(false);
                              form.func(
                                reqRes?.data || reqRes,
                                formData || requestData,
                                () => {
                                  setRequestData({});
                                  setClear(!clear);
                                },
                                GoBack
                              );
                            } catch (e) {
                              console.log("ADD func error", e);
                            }
                          }
                          if (
                            reqRes?.data?._id ||
                            reqRes?.data?.status === true
                          ) {
                            setRequestData({});
                            setClear(!clear);
                            if (popupShow === true)
                              setPopup({
                                title: "Sucesso",
                                description: "Deseja cadastrar novamente ?",
                                cancelText: "Não",
                                confirmText: "Sim",
                                onConfirm: async () => {
                                  await delay(500);
                                  setLoadingView(false);
                                },
                                onCancel: async () => {
                                  await delay(500);
                                  setLoadingView(false);
                                  GoBack();
                                },
                              });
                            else {
                              if (popupShow === true)
                                setPopup({
                                  title: "Erro",
                                  description: "Erro ao cadastrar",
                                  cancelText: "Voltar",
                                  confirmText: "Tentar novamente",
                                  onConfirm: () => {},
                                  onCancel: async () => {
                                    await delay(500);
                                    setLoadingView(false);
                                    GoBack();
                                  },
                                });
                            }
                          }
                          setLoadingView(false);
                        } catch (error) {
                          setLoadingView(false);
                          setRequestData({});
                        }
                      }
                      await delay(500);
                      setLoadingView(false);
                    },
                    children: [
                      {
                        component: "text",
                        text: "Salvar",
                        style: {
                          ...Theme.text.button,
                          fontSize: 14,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          })}
        </Fade>
      </div>
    ),
  };
};
