import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

//components/web
import CalendarView from "components/web/suggestHour";
import Button from "components/web/button";
import Input from "components/web/input";
import CurrencyInput from "components/web/inputCurrency";

//images
import Close from "assets/images/menu/close.png";

import Theme from "theme";

export default (serviceAddShow, setServiceAddShow, order, setDetails) => {
  const [serviceAdd, setServiceAdd] = useState("");
  const [serviceValue, setServiceValue] = useState("");
  const { PageInfo, GoPage, setUpdate } = ShowPage();

  const [username, setUsername] = useState("");

  const inputStyle = {
    // maxHeight: 140,
    input: {
      border: "1px solid #bfbfbf",
      minHeight: 42,
      borderRadius: 4,
      padding: 8,
      fontSize: 12,
      maxWidth: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    title: {
      fontSize: 14,
      marginBottom: 8,
    },
    select: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: 14,
    },
  };

  const inputName = Input({
    title: "Nome de identificação",
    value: serviceAdd,
    setValue: setServiceAdd,
    placeholder: "Ex: Shampoo adicional",
    style: {
      ...inputStyle,
    },
  });

  return {
    component: "container",
    style: {
      display: serviceAddShow === true ? "flex" : "none",
      width: "100%",
      minHeight: "100vh",
      maxHeight: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      alignItems: "center",
      borderRadius: 0,
      zIndex: 999,
    },
    children: [
      {
        render: (
          <Fade>
            {Render({
              component: "container",
              style: {
                display: "flex",
                width: "auto",
                height: "auto",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 12,
                backgroundColor: "white",
                borderRadius: 8,
                padding: 12,
              },
              children: [
                //labelEdit
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 12,
                  },
                  children: [
                    {
                      component: "text",
                      text: "Digite abaixo uma descrição do serviço adicional e o valor extra que será cobrado:",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 16,
                        textAlign: "center",
                        fontWeight: "400",
                      },
                    },
                  ],
                },

                inputName,

                CurrencyInput({
                  title: "Valor extra",
                  value: serviceValue,
                  style: {
                    input: {
                      border: "1px solid #bfbfbf",
                      minHeight: 42,
                      borderRadius: 4,
                      padding: 8,
                      fontSize: 12,
                      width: 128,
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    },
                    title: {
                      color: "gray",
                    },
                  },
                  onChange: async (e, val) => {
                    if (val === "") setServiceValue("");
                    else {
                      setServiceValue(val);
                    }
                  },
                }),

                Button({
                  label: "Confirmar serviço adicional",
                  style: {
                    button: {
                      alignItems: "center",
                      border: "none",
                      width: "auto",
                      color: "white",
                      height: 44,
                      borderRadius: 8,
                      cursor: "pointer",
                      backgroundColor: "#0864F1",
                      marginTop: 12,
                    },
                    label: {
                      fontFamily: Theme.fontFamilies.primary,
                      fontSize: 18,
                    },
                  },
                  onPress: async () => {
                    await api.order.addNewService({
                      order: order._id,
                      description: serviceAdd,
                      value: parseFloat(serviceValue),
                    });
                    setServiceAdd("");
                    setServiceValue("");
                    setServiceAddShow(false);
                    setDetails(false);
                    setUpdate("orders" + new Date());
                    GoPage("orders");
                  },
                }),
                Button({
                  label: "Cancelar",
                  style: {
                    button: {
                      alignItems: "center",
                      border: "none",
                      width: "auto",
                      color: "#0864F1",
                      height: 44,
                      borderRadius: 12,
                      cursor: "pointer",
                      backgroundColor: "transparent",
                    },
                    label: {
                      fontFamily: Theme.fontFamilies.primary,
                      fontSize: 18,
                    },
                  },
                  onPress: async () => {
                    setServiceAdd("");
                    setServiceValue("");
                    setServiceAddShow(false);
                  },
                }),
              ],
            })}
          </Fade>
        ),
      },
    ],
  };
};
