import { Render } from "@8_dev/sjson-web";
import Download from "assets/images/download-simple.png";
import Reload from "assets/images/reload.png";
import LoadingGif from "assets/images/loading.gif";
import getElement from "components/web/view/getElement";
import { ShowPage } from "context/showPage";
import { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import * as XLSX from "xlsx";
import Debounce from "hooks/debounce";
import Theme from "theme";
import Row from "./row";
import Cel from "./row";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
let lockReq = false;

export default (param) => {
  const { PageInfo } = ShowPage();

  useEffect(async () => {
    setInputSearchValue("");
    setSearchText("");
    setBtnSelected("");
    updateTableList("");
    setRequestFilter({
      count: param?.filter
        ?.map((item) => {
          return item.query;
        })
        .join(","),
      orderby: param?.orderby,
      orderasc: param?.orderasc,
      filterby_key: param?.filterby_key,
      filterby_op: param?.filterby_op,
      filterby_val: param?.filterby_val,
      limit: 32,
      page: 1,
    });
  }, [PageInfo.show]);

  const [searchText, setSearchText] = useState("");
  const [rowHover, setRowHover] = useState(0);
  const [listRow, setListRow] = useState([]);
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([
    // {
    //   id: "",
    //   key: ["ggg", "kkk"], // filtro chave
    //   val: [""], // valor normalized and low case
    // },
  ]);

  const [navShow, setNavShow] = useState(true);
  const [navInfo, setNavInfo] = useState({
    // count: {
    //   ...param?.filter?.map((item) => {
    //     return {
    //       [item.query]: "",
    //     };
    //   }),
    // },
    count: param?.filter?.reduce((acc, curr) => ((acc[curr] = ""), acc), {}),
    page: 1,
    totalPage: 0,
    totalData: 0,
  });
  // criar line hover e action ccolumns

  const [info, setInfo] = useState({
    fee: 0,
    count: 0,
    order: 0,
    discount: 0,
    investment: 0,
    people: [],
  });

  const colName = Object.keys(param.col);

  const textStyle = {
    fontFamily: Theme.fontFamilies.primary,
  };

  const [searchTimeDelay, setSearchTimeDelay] = useState(750);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const debouncedSearchValue = Debounce(inputSearchValue, searchTimeDelay);

  const [loadingView, setLoadingView] = useState(false);
  const loading = {};

  // relizar buscar locais quando estiver offline, repeitar regras de navegacao... retornar pro fluxo do nav
  const searchItem = async (text, searchData, force, download) => {
    if (!searchData) searchData = dataFilter;
    let downloadList = [];

    if (!download && text.length < 3 && force) {
      // setListRow(
      //   searchData.map((data) => {
      //     return data.item;
      //   })
      // );

      setSearchText("");
      // updateTableList(requestFilter);
      setNavShow(true);
      return 1;
    }
    if (!download && text.length <= 3) {
      setListRow(data);
      setSearchText("");
      // updateTableList(requestFilter);
      setNavShow(true);
      return 1;
    } else if (!download && !text) {
      setSearchText("");
      // updateTableList(requestFilter);
      setNavShow(true);
      return 1;
    }

    let searchType = "";
    if (text.startsWith("/")) {
      searchType = "eq";
      text = text.split("/")[1];
    }

    text = text.toLowerCase().replace(/ /g, "");
    setSearchText(text);
    setNavShow(false);

    // se download for false pq ja vem tudo la e ai so att msm e fazer download da lista final
    // fazer busca inicial na listagem
    // e solicitar o resto ao banco passando text e limit=0

    let filterList = [];
    await Promise.all(
      searchData.map(async (data) => {
        let found = false;
        data.val.map((v) => {
          if (found) return;
          else if (searchType === "eq") {
            if (v === text) found = true;
            return false;
          } else if (v.startsWith(text)) found = true;
          else if (v.includes(text)) found = true;
        });

        if (found) filterList.push(data.item);
        if (found && download)
          downloadList.push(
            colName.map((c) => {
              if (param.col[c]?.key)
                return dynamicSearch(
                  { ...data.item },
                  Array.isArray(param.col[c].key)
                    ? [...param.col[c].key]
                    : param.col[c].key.split(".")
                );
              else if (param.col[c]?.condition)
                return rowConditionValue(data.item, c);
              else return data.item[c] || "";
            })
          );
      })
    );
    // console.log(filterList);

    setListRow(filterList);

    if (!download && !force && text.length > searchText.length) {
      updateTableList({
        skip: filterList.length,
        textSearch: text,
      });
    }
    setLoadingView(false);
    return downloadList;

    // iniciar buscar aposta listagem em modo paralelo e adicionar final da lista
    // a lista ja deve vir filtrada conforma o texto enviado , vai buscar somente esse info no banco
    // depois disso e subir os ajuste pra lib principal e centralizar tudo
    // abrir code da lib principal
    // abrir code das lib de cada projeto
  };

  const updateTableList = async (query) => {
    if (lockReq) return;
    lockReq = true;
    setLoadingView(true);

    if (!requestFilter.filter && !query.filter) setBtnSelected("");

    if (query?.download) {
      query.limit = 0;
      query.page = 1;
    }
    if (param?.item) {
      if (Array.isArray(param.item)) {
        if (param.item.length === 0) return;
        setListRow(param.item);
        setData(param.item);

        let searchList = [];
        const searchKey = Object.keys(param.item[0]);

        param.item.map((item) => {
          searchList.push({
            id: item._id,
            val: searchKey.map((k) => {
              if (item[k] && typeof item[k] === "string")
                return item[k]?.toLowerCase().replace(/ /g, "");
              else if (param.col[k]?.key)
                return dynamicSearch(
                  { ...item },
                  Array.isArray(param.col[k].key)
                    ? [...param.col[k].key]
                    : param.col[k].key.split(".")
                )
                  .toLowerCase()
                  .replace(/ /g, "");
              else if (param.col[k]?.condition)
                return rowConditionValue(item, k)
                  .toLowerCase()
                  .replace(/ /g, "");
              else return "";
            }),
            item,
            key: searchKey,
          });
        });
        setLoadingView(false);
        setDataFilter(searchList);
      } else {
        // verificar o que ja tem nesse historico local
        // verificar se na chaves de histico tem a composicao da palavra ou a palavra
        // obter o conteudo e realizar a filtragem local otimizando sem fazer request
        // atualizar infos de Mostragem , Atravez do caminho Nav -> table (att download List)

        param
          .item({
            count: param?.filter
              ?.map((item) => {
                return item.query;
              })
              .join(","),
            query,
            orderby: param?.orderby,
            orderasc: param?.orderasc,
            filterby_key: param?.filterby_key,
            filterby_op: param?.filterby_op,
            filterby_val: param?.filterby_val,
          })
          .then(async ({ data }) => {
            if (!data?.data) return;
            // passar parametros download para props
            if (query?.download) {
              generateXLSX(
                [
                  colName.map((col) => {
                    return param.col[col].title;
                  }),
                  ...data.data.list,
                ],
                "download.xlsx",
                "lista"
              );
            } else {
              if (!data.data.list && Array.isArray(data.data))
                data.data.list = data.data;
              setListRow(data.data.list);
              setData(data.data.list);
              setNavInfo(data.data.nav);
              setNavShow(true);
            }
            setLoadingView(false);
            lockReq = false;
          });
      }
    }
  };

  const [requestData, setRequestData] = useState({});
  let tableStyle = {
    col: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "nowrap",
      overflowY: "hidden",
      ...param?.style?.col,
    },

    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "nowrap",
      overflowY: "hidden",
      width: "auto",
      fontSize: Theme.fontSizes.row,
      ...param?.style?.row,
    },

    cel: param?.style?.cel,
    rowIndex: {
      // 0: { backgroundColor: "red" },
      ...param?.style?.rowIndex,
    },

    nav: {
      container: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 4,
        margin: 4,
      },
      text: {
        color: "rgba(0,0,0,1)",
        fontSize: Theme.fontSizes.normal,
        fontFamily: "Poppins",
        padding: 5,
      },
      select: {
        fontSize: Theme.fontSizes.normal,
        fontFamily: "Poppins",
        padding: 8,
        margin: 4,
        color: "rgba(0,0,0, .64)",
        border: "0.1vh dotted rgba(0,0,0, .32)",
        borderRadius: 4,
        maxWidth: 124,
      },
      button: {
        fontSize: Theme.fontSizes.normal,
        color: Theme.colors.black.full,
        fontWeight: "400",
        fontFamily: "Poppins",
        textDecoration: "none",
        cursor: "pointer",
        padding: 4,
        borderRadius: 4,
        // backgroundColor: "rgba(100,100,100,0.16)",
      },
      box: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        justifyContent: "center",
        padding: 4,
        margin: 4,
        marginLeft: 4,
        marginRight: 4,
        minWidth: 32,
        maxWidth: 248,
      },
    },
  };

  const getRowStyle = (id) => {
    return tableStyle.rowIndex[id] || tableStyle.row;
  };

  const dynamicSearch = (param, searchKey) => {
    const result = [];

    const recursiveSearch = (item, keys) => {
      const key = keys.shift();
      if (item && typeof item === "object") {
        if (Array.isArray(item) && !isNaN(parseInt(key))) {
          // Se for um array e a chave for um número, verifica se é um índice válido
          const index = parseInt(key);
          if (index >= 0 && index < item.length) {
            if (keys.length === 0) {
              // Se não houver mais chaves, adiciona o valor ao resultado
              result.push(item[index]);
            } else {
              // Caso contrário, continua a busca recursiva
              recursiveSearch(item[index], keys);
            }
          }
        } else if (key in item) {
          if (keys.length === 0) {
            // Se não houver mais chaves, adiciona o valor ao resultado
            if (typeof item[key] === "string") {
              result.push(item[key]);
            }
          } else {
            // Caso contrário, continua a busca recursiva
            recursiveSearch(item[key], keys);
          }
        } else if (Array.isArray(item) && typeof key === "string") {
          // Se for um array e a chave for uma string, faz uma busca usando filter
          const filtered = item.filter(
            (element) => element[key] && typeof element[key] === "string"
          );
          if (filtered.length > 0) {
            filtered.forEach((element) => {
              result.push(element[key]);
            });
          }
        }
      }
    };

    recursiveSearch(param, searchKey);

    return result[0];
  };

  const rowConditionValue = (item, col) => {
    try {
      if (param.col[col] && param.col[col].condition)
        return param.col[col].condition(item);
      return "";
    } catch (e) {
      console.log("Error row condition", e);
      return "";
    }
  };

  const rowElements = colName?.map((col) => {
    return {
      component: "container",
      style: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flex: param.col[col]?.style?.flex || 1,
        minHeight: "100%",
      },
      children: [
        {
          component: "text",
          text: param.col[col].title || "",
          style: {
            flex: 1,
            ...textStyle,
            ...tableStyle.col,
            // ...param.style?.col[col],
            // ...param.col[col]?.style,
            minHeight: "100%",
          },
        },
      ].concat(
        // Fazer criar Pelo getElemente
        // Utiliza Box, e adicionar div e elemento comuns..
        // Melhor lib do Get elent com gpt

        listRow?.map((row, rowIndex) => {
          // item complexo para acoes personalizadas
          const item = row;
          if (param.col[col]?.item) {
            return {
              component: "container",
              style: {
                ...textStyle,
                ...tableStyle.col,
                ...tableStyle.row,
                minHeight: "100%",
                height: tableStyle.row?.maxHeight || "100%",
              },
              children: param.col[col].item.map((child) => {
                let onClick = () => {};
                let onChange = () => {};
                if (child?.onClick)
                  onClick = async () => {
                    setLoadingView(true);

                    // console.log("oxxi", item);
                    const res = await child.onClick(row);
                    if (res) {
                      listRow[rowIndex] = res;
                      await setListRow([...listRow]);
                    }
                    setLoadingView(false);
                  };

                if (child?.onChange)
                  onChange = async () => {
                    setLoadingView(true);

                    // console.log("oxxi", item);
                    const res = await child.onChange(row);
                    if (res) {
                      listRow[rowIndex] = res;
                      await setListRow([...listRow]);
                    }
                    setLoadingView(false);
                  };

                if (child.id)
                  child.style = tableStyle.cel({ item: row, col: child.id });

                return child.type
                  ? getElement({ ...child, onClick, onChange })
                  : child.value?.component
                  ? {
                      render: (
                        <Fade top cascade>
                          {Render({
                            component: "container",
                            style: {},
                            children: [child.value],
                          })}
                        </Fade>
                      ),
                    }
                  : Array.isArray(child.value)
                  ? child.value
                  : child.value({ row });
              }),
            };
          }
          if (!row.id) row.id = rowIndex;

          if (!row.type)
            row = {
              label: param.col[col]?.key
                ? dynamicSearch(
                    { ...row },
                    Array.isArray(param.col[col].key)
                      ? [...param.col[col].key]
                      : param.col[col].key.split(".")
                  )
                : param.col[col]?.condition
                ? rowConditionValue(row, col)
                : row[col] || "",
              style: {
                // ...textStyle,
                ...tableStyle.cel({ item: row, col }),
              },
              onClick: param.col[col]?.onClick
                ? () => {
                    param.col[col]?.onClick(item);
                  }
                : async () => {
                    // console.log(param);
                  },
            };
          else {
            if (row[col])
              requestData[row.id] = row.props = {
                value: requestData[row.id],
                set: async ({ k, v }) => {
                  if (!k) return;

                  if (k.includes(".")) {
                    const [fKey, ...objKey] = k.split(".");

                    if (objKey.length === 0) {
                      setRequestData({ ...requestData, [objKey]: v });
                      return;
                    }

                    const lKey = objKey.pop();
                    const ffk = objKey.shift();

                    let itemValue = requestData;

                    if (!itemValue) itemValue = { [fKey]: [] };
                    else if (!itemValue[fKey]) itemValue[fKey] = [];

                    itemValue = itemValue[fKey];

                    if (itemValue === undefined) itemValue = { [ffk]: {} };
                    else if (!itemValue[ffk]) itemValue[ffk] = {};

                    itemValue = itemValue[ffk];

                    objKey.map((objk) => {
                      if (itemValue[objk] === undefined) itemValue[objk] = {};
                      itemValue = itemValue[objk];
                    });

                    if (itemValue === undefined) itemValue = { [lKey]: v };
                    else itemValue[lKey] = v;

                    setRequestData({ ...requestData });
                  } else {
                    setRequestData({
                      ...requestData,
                      [k]: v,
                    });
                  }
                },
                // para deletar uma posicao
                delete: async (id) => {
                  const newList = [];
                  requestData[row.id].map((row, index) => {
                    if (index !== id)
                      newList.push({ ...row, id: newList.length });
                  });

                  requestData[row.id] = newList;
                  // delete requestData[row.id][id];
                  // console.log("delete", newList, requestData[row.id]);
                  setInfo({ ...requestData });
                },
              };
          }

          tableStyle.row["flex"] = param.col[col]?.style?.flex || 1;
          tableStyle.col["flex"] = param.col[col]?.style?.flex || 1;

          // tableStyle.cel["minHeight"] = param.style?.row.maxHeight || "auto";

          return Row({
            id: rowIndex,
            col,
            item: row,
            style: tableStyle,
            getRowStyle,
          });
        })
      ),
    };
  });

  // Função auxiliar para simplificar a criação de componentes
  function createComponent(type, options) {
    const { style, ...otherOptions } = options;
    // console.log("elementt", type);
    const ele = getElement({
      type,
      ...otherOptions,
      style: { ...tableStyle.nav[type], ...style }, // Mescla estilos padrões com estilos específicos
    });
    // console.log("ele", ele);
    return ele;
  }

  // cria a varivel que vai ser setada em cada prte da request da api
  // como enviar isso no parametro da api ?? quando executar func ?? Testar effect e update ....
  // trazer dados da resposta da api e configurar os values e quantidades

  // quando fizer a buscar enviar paramtro para trazer todas as infos do banco complementar... pois resquest vai buscar local e na nuvem instant ...

  const generatePaginationButtons = (totalPages, startPage, page) => {
    const buttons = [];
    const half = 1;
    let mult = 1;
    let thisBtn = true;
    let count = 0;

    if (!startPage) startPage = 1;

    for (let i = startPage; i <= startPage + totalPages; i++) {
      if (count > totalPages) break;
      else count++;

      if ((i === page || i > page) && thisBtn) {
        buttons.push({
          type: "button",
          label: page.toString(),
          style: {
            fontWeight:
              requestFilter.page === page
                ? "600"
                : tableStyle.nav.button.fontWeight,
            fontSize:
              requestFilter.page === page
                ? Theme.fontSizes.big
                : tableStyle.nav.button.fontSize,
            textDecoration:
              requestFilter.page === page
                ? "underline"
                : tableStyle.nav.button.textDecoration,
          },
          onClick: async () => {
            setRequestFilter({ ...requestFilter, page });
          },
        });
        thisBtn = false;
      } else {
        // if (count > 1) i *= mult;
        if (count % half === 0) mult++;

        const button = {
          type: "button",
          label: i.toString(),
          style: {
            fontWeight:
              requestFilter.page === i
                ? "600"
                : tableStyle.nav.button.fontWeight,
            fontSize:
              requestFilter.page === i
                ? Theme.fontSizes.big
                : tableStyle.nav.button.fontSize,
            textDecoration:
              requestFilter.page === i
                ? "underline"
                : tableStyle.nav.button.textDecoration,
          },
          onClick: async () => {
            setRequestFilter({ ...requestFilter, page: i });
          },
        };
        buttons.push(button);
      }
    }

    return buttons;
  };

  const [requestFilter, setRequestFilter] = useState({
    count: param?.filter
      ?.map((item) => {
        return item.query;
      })
      .join(","),
    orderby: param?.orderby,
    orderasc: param?.orderasc,
    filterby_key: param?.filterby_key,
    filterby_op: param?.filterby_op,
    filterby_val: param?.filterby_val,
    limit: 32,
    page: 1,
  });

  // Função para criar um arquivo Excel a partir de uma matriz de dados e fazer download no navegador
  const generateXLSX = (data, filename, sheetName) => {
    // Criar uma nova planilha XLSX
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName || "Sheet1");

    // Converter o arquivo Excel para um blob
    const wbData = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const wbBlob = new Blob([wbData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Criar uma URL temporária para o blob
    const wbUrl = URL.createObjectURL(wbBlob);

    // Abrir a URL em uma nova guia do navegador para fazer o download
    const link = document.createElement("a");
    link.href = wbUrl;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();

    // Liberar o blob da memória após o download
    URL.revokeObjectURL(wbUrl);
  };

  const loadXLSX = (fileName) => {
    const workbook = XLSX.readFile(fileName);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    return XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  };

  const navBar = [
    createComponent(
      "div",
      {
        style: {
          ...tableStyle.nav.box,
          flexDirection: "row",
          gap: 4,
          padding: 0,
          margin: 0,
          alignItems: "center",
          justifyContent: "center",
          display: navInfo.totalPage > 1 ? "flex" : "none",
        },
        children: [
          {
            type: "button",
            label: "<",
            style: {
              display: "flex",
            },
            onClick: async () => {
              if (requestFilter.page > 1)
                setRequestFilter({
                  ...requestFilter,
                  page: requestFilter.page - 1,
                });
            },
          },

          {
            type: "div",
            style: {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 4,
              padding: 0,
              margin: 0,
            },
            children: [
              //Start Page
              {
                type: "button",
                label: "Inicio",
                style: {
                  label: {
                    fontSize: "1.32vh",
                    fontWeight: "400",
                  },
                },
                onClick: async () => {
                  setRequestFilter({
                    ...requestFilter,
                    page: 1,
                  });
                },
              },
              // Count Pages
              {
                type: "div",
                style: {
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "nowrap",
                  // backgroundColor: "rgba(100,100,100,0.16)",
                  gap: 8,
                  padding: 0,
                  margin: 0,
                  overflow: "hidden",
                  maxWidth: 128,
                },
                children: generatePaginationButtons(
                  navInfo.totalPage === 1
                    ? 0
                    : navInfo.totalPage < 4
                    ? navInfo.totalPage - navInfo.page
                    : navInfo.totalPage - navInfo.page > 4
                    ? 4
                    : navInfo.totalPage - (navInfo.page - 2),
                  navInfo.page > 4
                    ? navInfo.page - 2
                    : navInfo.page > 1
                    ? navInfo.page - 1
                    : 1,
                  navInfo.page
                ),
                // children: generatePaginationButtons(
                //   navInfo.totalPage === 1
                //     ? 0
                //     : navInfo.totalPage < 4
                //     ? navInfo.totalPage - navInfo.page
                //     : navInfo.totalPage - navInfo.page > 4
                //     ? 4
                //     : navInfo.totalPage - (navInfo.page - 2),
                //   navInfo.page > 4
                //     ? navInfo.page - 2
                //     : navInfo.page > 1
                //     ? navInfo.page - 1
                //     : 1,
                //   navInfo.page
                // ),
              },

              // end page
              {
                type: "button",
                label: "Fim ( " + navInfo.totalPage + " )",
                style: {
                  label: {
                    fontSize: "0.88vw",
                    fontWeight: "400",
                  },
                },
                onClick: async () => {
                  // console.log(requestFilter);
                  setRequestFilter({
                    ...requestFilter,
                    page: navInfo.totalPage || 1,
                  });
                },
              },
            ],
          },

          {
            type: "button",
            label: ">",
            style: {
              display: "flex",
            },
            onClick: async () => {
              // verificar limite da array len
              setRequestFilter({
                ...requestFilter,
                page:
                  requestFilter.page < navInfo.totalPage
                    ? requestFilter.page + 1
                    : requestFilter.page,
              });
            },
          },
        ],
      },

      {
        type: "div",
        style: {
          flexDirection: "column",
          gap: 4,
          padding: 0,
          margin: 0,
        },
        children: [
          {
            type: "div",
            style: {
              flexDirection: "column",
              gap: 4,
              padding: 0,
              margin: 0,
            },
            children: [
              {
                // type: "div",
                label: "Registros",
                style: {
                  fontSize: Theme.fontSizes.small,
                  fontWeight: "400",
                },
              },

              {
                type: "div",
                style: {
                  flexDirection: "row",
                  gap: 4,
                  padding: 0,
                  margin: 0,
                },
                children: [
                  {
                    label:
                      parseInt(navInfo.page - 1 || 0) *
                        parseInt(navInfo.limit || 0) || 0,
                    style: {
                      fontSize: Theme.fontSizes.small,
                      fontWeight: "400",
                      display: navInfo.totalPage > 1 ? "flex" : "none",
                    },
                  },
                  {
                    label: "-",
                    style: {
                      fontSize: Theme.fontSizes.small,
                      fontWeight: "400",
                      display:
                        navInfo.totalPage === 1 ||
                        navInfo.totalData <
                          parseInt(navInfo.page || 0) *
                            parseInt(navInfo.limit || 0)
                          ? "none"
                          : "flex",
                    },
                  },
                  {
                    label:
                      parseInt(navInfo.page || 0) *
                        parseInt(navInfo.limit || 0) || "",
                    style: {
                      fontSize: Theme.fontSizes.small,
                      fontWeight: "400",
                      display:
                        navInfo.totalPage === 1 ||
                        navInfo.totalData <
                          parseInt(navInfo.page || 0) *
                            parseInt(navInfo.limit || 0)
                          ? "none"
                          : "flex",
                    },
                  },
                  {
                    label: " de ",
                    style: {
                      fontSize: Theme.fontSizes.small,
                      fontWeight: "400",
                      display: navInfo.totalPage > 1 ? "flex" : "none",
                    },
                  },
                  {
                    label: navInfo.totalRecords,
                    style: {
                      fontSize: Theme.fontSizes.small,
                      fontWeight: "400",
                    },
                  },
                ],
              },
            ],
          },
          {
            type: "select",
            id: "linesPagesLength",
            label: "Linhas por Pagina",
            style: {
              display: "none",
              display: navInfo.totalPage > 1 ? "flex" : "flex",
              // backgroundColor: "blue",
            },
            onChange: (limit) => {
              setRequestFilter({ ...requestFilter, page: 1, limit });
            },
            value: requestFilter.limit || 16,
            option: [
              { _id: 16, name: "16" },
              { _id: 32, name: "32" },
              { _id: 64, name: "64" },
              { _id: 128, name: "128" },
            ],
          },
        ],
      }
    ),
  ];

  const InputSearch = createComponent("input", {
    id: "searchText",
    label: "Buscar",
    placeHolder: "Busque",
    style: {
      input: {
        flex: 1,
        maxHeight: 80,
        minHeight: "6%",
        width: "100%",
        aspectRatio: 3,
        fontSize: 18,
        borderRadius: 4,
        backgroundColor: "transparent",
        border: "1px solid rgba(100, 100, 100, 0.3)",
        color: "rgba(100, 100, 100, 0.9)",
      },
      title: {
        fontSize: 16,
        marginBottom: 4,
      },
    },
    props: {
      value: searchText,
      set: async ({ k, v }) => {
        setSearchText(v);
        if (v.length === 0) {
          updateTableList({
            ...requestFilter,
          });
          setInputSearchValue("");
          setSearchText("");
        } else {
          setSearchTimeDelay(searchTimeDelay + 50);
          setInputSearchValue(v);
        }
      },
    },
  });
  //  caso a resposta da api venha com length maior realizar a paginacao inteligente
  // quebrar uma array em partes para criar padrao de paginacao

  // Efeito para executar a busca após o delay
  useEffect(() => {
    if (debouncedSearchValue) {
      setSearchTimeDelay(750);
      // Executar a busca com debouncedSearchValue
      // console.log(`Buscando por: ${debouncedSearchValue}`);
      // Aqui você pode adicionar sua lógica para buscar dados baseado no debouncedSearchValue
      if (debouncedSearchValue.length >= 3) {
        lockReq = false;

        updateTableList({
          textSearch: debouncedSearchValue,
        });

        // 1 - buscar enviado texto e filtros...
        // iniciar buscar npo banco com o texto
        // a resposat ja deve vir filtrada
        // config Nav e iniciar insert das rows gerenciado pelo nav
        // adicionas as rows
        // adicionas sistema de nav
      }
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    setSearchText("");
    setInputSearchValue("");
    updateTableList();
    setBtnSelected("");
  }, []);

  useEffect(async () => {
    updateTableList({
      ...requestFilter,
      textSearch: inputSearchValue,
    });
  }, [requestFilter]);

  const inputStyle = {
    button: {
      height: "100%",
      borderRadius: 4,
      padding: 4,
      fontSize: "2vh",
    },
    label: {
      ...Theme.text.input.title,
      color: "black",
    },
  };
  const [btnSelected, setBtnSelected] = useState("");

  const handleButtonClick = (item) => {
    setRequestFilter({
      ...requestFilter,
      page: 1,
      filter: item.query,
    });
    setBtnSelected(item.id);
    updateTableList({
      ...requestFilter,
      page: 1,
      textSearch: inputSearchValue,
      filter: item.query,
    });
    if (item.onClick) {
      item.onClick();
    }
  };

  return {
    update: async (info) => {
      setSearchText("");
      setInputSearchValue("");
      if (!info) return;
      setLoadingView(true);
      // console.log("req");
      if (Array.isArray(info)) {
        if (info.length === 0) return;
        setListRow(info);
        setData(info);

        let searchList = [];
        const searchKey = Object.keys(info[0]);

        info.map((item) => {
          searchList.push({
            id: item._id,
            val: searchKey.map((k) => {
              if (item[k] && typeof item[k] === "string")
                return item[k]?.toLowerCase().replace(/ /g, "");
              else if (param.col[k]?.key)
                return dynamicSearch(
                  { ...item },
                  Array.isArray(param.col[k].key)
                    ? [...param.col[k].key]
                    : param.col[k].key.split(".")
                )
                  .toLowerCase()
                  .replace(/ /g, "");
              else if (param.col[k]?.condition)
                return rowConditionValue(item, k)
                  .toLowerCase()
                  .replace(/ /g, "");
              else return "";
            }),
            item,
            key: searchKey,
          });
        });

        setDataFilter(searchList);
        setLoadingView(false);
      } else
        info({ query: requestFilter }).then(({ data }) => {
          setLoadingView(false);
          if (!data?.data) return;
          if (!data.data.list && Array.isArray(data.data))
            data.data.list = data.data;
          setListRow(data.data.list);
          setData(data.data.list);
          setNavInfo(data.data.nav);

          // let searchList = [];
          // if (!data?.data?.length) return;
          // const searchKey = Object.keys(data.data[0]);

          // data.data.list.map((item) => {
          //   searchList.push({
          //     id: item._id,
          //     val: searchKey.map((k) => {
          //       if (item[k] && typeof item[k] === "string")
          //         return item[k]?.toLowerCase().replace(/ /g, "");
          //       else if (param.col[k]?.key)
          //         return dynamicSearch(
          //           { ...item },
          //           Array.isArray(param.col[k].key)
          //             ? [...param.col[k].key]
          //             : param.col[k].key.split(".")
          //         )
          //           .toLowerCase()
          //           .replace(/ /g, "");
          //       else if (param.col[k]?.condition)
          //         return rowConditionValue(item, k)
          //           ?.toLowerCase()
          //           .replace(/ /g, "");
          //       else return "";
          //     }),
          //     item,
          //     key: searchKey,
          //   });
          // });

          // setDataFilter(searchList);
        });
      setLoadingView(false);
    },
    rowStyle: async (value) => {
      tableStyle.rowIndex = value;
    },
    component: {
      component: "container",
      style: {
        width: "100%",
        minWidth: "100%",
        height: "100%",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
      component: "container",
      style: {
        width: "100%",
        minWidth: "100%",
        height: "100%",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
      children: [
        // loading
        {
          component: "container",
          style: {
            top: 0,
            left: 0,
            width: "100%",
            minWidth: "100%",
            height: "100%",
            maxHeight: "100%",
            position: "absolute",
            backgroundColor: Theme.colors.gray.background,
            display: loadingView ? "flex" : "none",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 999,
          },
          children: [
            {
              component: "container",
              style: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 999,
                gap: -16,
              },
              children: [
                {
                  render: (
                    <img
                      style={{
                        display: "flex",
                        aspectRatio: "1/1",
                        width: "10%",
                      }}
                      src={LoadingGif}
                    />
                  ),
                },
                {
                  render: (
                    <img
                      style={{
                        display: "flex",
                        aspectRatio: "1/1",
                        width: "10%",
                      }}
                      src={LoadingGif}
                    />
                  ),
                },
                {
                  render: (
                    <img
                      style={{
                        display: "flex",
                        aspectRatio: "1/1",
                        width: "10%",
                      }}
                      src={LoadingGif}
                    />
                  ),
                },
              ],
            },
          ],
        },
        // Top Bar Colocar Filtros (Buttons)
        {
          component: "container",
          style: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: 4,
          },
          children: [
            //filterBox
            {
              component: "container",
              style: {
                display: param.filter?.length > 0 ? "flex" : "none",
                alignItems: "center",
                borderRadius: 8,
                gap: 8,
                padding: 4,
                backgroundColor: "rgba(228, 238, 255, 1)",
                ...param?.style?.filterBox,
              },
              children: param?.filter?.map((item) => {
                return {
                  component: "container",
                  style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor:
                      btnSelected === item.id ? "white" : "transparent",
                    padding: 2,
                    borderRadius: 4,
                    padding: 4,
                    gap: 4,
                    ...item?.style?.box,
                  },
                  children: [
                    //criar count que recebe o dado de uma api e faz seu monitoramento
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        width: 24,
                        height: 24,
                        borderRadius: 4,
                        alignItems: "center",
                        justifyContent: "center",
                        background: "rgba(255, 183, 183, 1)",
                      },
                      children: [
                        {
                          component: "text",
                          text: navInfo?.count[item?.query] || "-",
                          style: {
                            fontFamily: Theme.fontFamilies.primary,
                            fontSize: 12,
                          },
                        },
                      ],
                    },
                    getElement({
                      type: item.type,
                      label: item.label,
                      style: {
                        ...inputStyle,
                        ...item?.style?.button,
                      },
                      onClick: () => {
                        handleButtonClick(item);
                      },
                    }),
                  ],
                };
              }),
            },
            // Title(notUsed)
            {
              component: "text",
              text: param.title || "",
              style: {
                color: "rgba(0,0,0,1)",
                fontSize: Theme.fontSizes.big,
                fontFamily: "Poppins",
                padding: 5,
              },
            },
            // Input de pesquisa + Btn Excel Download export + Btn reloadList
            {
              component: "container",
              style: {
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "center",
                gap: 32,
              },
              children: [
                InputSearch, //pesquisa
                //Btn Excel Download export
                {
                  component: "button",
                  style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 32,
                    padding: 4,
                    aspectRatio: "1/1",
                    backgroundColor: "#0864F1",
                    borderRadius: 4,
                    border: "1px solid white",
                    cursor: "pointer",
                  },
                  onClick: async () => {
                    updateTableList({
                      ...requestFilter,
                      textSearch: inputSearchValue,
                      download: "excel",
                    });
                    // console.log("verRes", requestFilter);
                  },
                  children: [
                    {
                      render: (
                        <img
                          style={{
                            display: "flex",
                            aspectRatio: "1/1",
                            width: "100%",
                          }}
                          src={Download}
                        />
                      ),
                    },
                  ],
                },
                //Btn reloadList
                {
                  component: "button",
                  style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 32,
                    padding: 4,
                    aspectRatio: "1/1",
                    borderRadius: 4,
                    backgroundColor: "transparent",
                    border: "1px solid transparent",
                    cursor: "pointer",
                  },
                  onClick: async () => {
                    setInputSearchValue("");
                    setSearchText("");
                    setBtnSelected("");
                    setRequestFilter({
                      count: param?.filter
                        ?.map((item) => {
                          return item.query;
                        })
                        .join(","),
                      orderby: param?.orderby,
                      orderasc: param?.orderasc,
                      filterby_key: param?.filterby_key,
                      filterby_op: param?.filterby_op,
                      filterby_val: param?.filterby_val,
                      limit: 32,
                      page: 1,
                    });
                    updateTableList({
                      count: param?.filter
                        ?.map((item) => {
                          return item.query;
                        })
                        .join(","),
                      orderby: param?.orderby,
                      orderasc: param?.orderasc,
                      filterby_key: param?.filterby_key,
                      filterby_op: param?.filterby_op,
                      filterby_val: param?.filterby_val,
                      limit: 32,
                      page: 1,
                    });
                  },
                  children: [
                    {
                      render: (
                        <img
                          style={{
                            display: "flex",
                            aspectRatio: "1/1",
                            width: "100%",
                          }}
                          src={Reload}
                        />
                      ),
                    },
                  ],
                },
              ],
            },
          ],
        },
        //Table List
        {
          component: "container",
          style: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: "1%",
            width: "100%",
            minWidth: "100%",
            maxHeight: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            padding: 8,
            flex: 1,
          },
          children: rowElements,
        },
        //Nav bar
        {
          component: "container",
          style: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: 4,
            margin: 4,
          },
          children: [
            {
              component: "container",
              style: {
                flex: 1,
                height: "100%",
                fontFamily: "Poppins",
                padding: 5,
                display: navShow ? "flex" : "none",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              },
              children: navBar,
            },
            // colocar container com paginas com range de 3 pra frente e 4 sendo ultima posicao para controle de navegacao
          ],
        },
      ],
    },
  };
};
