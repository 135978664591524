import Theme from "theme";
import React, { useState, useEffect } from "react";
import { Slider, InputNumber } from "antd";
import CurrencyInput from "react-currency-masked-input";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export default (props) => {
  return {
    component: "container",
    style: {
      display: "flex",
      minWidth: "100%",
      height: "auto",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      padding: 2,
      gap: 2,
    },
    children: [
      {
        component: "text",
        text: props.title,
        style: {
          fontSize: 14,
          fontFamily: "Poppins",
          marginBottom: 8,
          ...props?.style?.title,
        },
      },
      {
        component: "container",
        style: {
          display: "flex",
          width: "auto",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          gap: 4,
        },
        children: [
          {
            component: "text",
            text: props.coin || "R$",
            style: {
              fontSize: 18,
              fontFamily: "Poppins",
            },
          },
          {
            render: (
              <CurrencyInput
                value={props?.value}
                onChange={props?.onChange}
                style={{
                  width: "100%",
                  height: 42,
                  borderRadius: 4,
                  color: "rgba(100,100,100,1)",
                  fontSize: 18,
                  fontFamily: "Poppins",
                  borderWidth: 0,
                  paddingLeft: "1%",
                  ...props?.style?.input,
                }}
              />
            ),
          },
        ],
      },
    ],
  };
};
