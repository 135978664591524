import { useEffect, useState } from "react";

import api from "service";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

import CategoryList from "components/web/categoryList/schedules";
import ScheduleList from "components/web/scheduleList";

import Button from "components/web/button";
import PopUp from "components/web/popup";

import { Context } from "context/data";

import Theme from "theme";

// importar pagina padrao e criar variveis para add e listar...
// import AddClient from "pages/admin/client/add";
// import ListClient from "pages/admin/client/list";

import Icon from "components/web/icon";

export default () => {
  const { PageInfo, GoPage } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();
  const { Data, Set } = Context();

  const [isEdit, setIsEdit] = useState(false);

  const [schedule, setSchedule] = useState({});

  const [categoryItem, setCategoryItem] = useState({});

  const [weekHours, setWeekHours] = useState({
    0: {
      code: 0,
      name: "Domingo",
      enabled: false,
      off_hours: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    },
    1: {
      code: 1,
      name: "Segunda-feira",
      enabled: false,
      off_hours: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    },
    2: {
      code: 2,
      name: "Terça-feira",
      enabled: false,
      off_hours: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    },
    3: {
      code: 3,
      name: "Quarta-feira",
      enabled: false,
      off_hours: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    },
    4: {
      code: 4,
      name: "Quinta-feira",
      enabled: false,
      off_hours: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    },
    5: {
      code: 5,
      name: "Sexta-feira",
      enabled: false,
      off_hours: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    },
    6: {
      code: 6,
      name: "Sábado",
      enabled: false,
      off_hours: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    },
  });

  const [categoryHour, setCategoryHour] = useState([
    {
      category: "",
      disabled: false,
      interval: 1,
      week: weekHours,
    },
  ]);

  useEffect(async () => {
    if (PageInfo.show === "schedules") {
      let cSchedule = {};
      Data.user?.schedule?.map((item) => {
        cSchedule[item.category] = item;
      });
      // console.log("VerSchedule", cSchedule);
      setSchedule(cSchedule);
      setCategoryItem({});
    }
  }, [PageInfo.show]);

  //Lista de Usuários gerais
  return {
    component: "container",
    style: {
      display: PageInfo.show === "schedules" ? "flex" : "none",
      flexDirection: "column",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100%",
      padding: "1%",
    },
    children: [
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "flex-start",
          flexDirection: "column",
          gap: 24,
          paddingLeft: 24,
          paddingRight: 24,
        },
        children: [
          //component de categorias
          CategoryList({
            edit: isEdit,
            category: schedule,
            onChange: {
              switch: (itemId, status) => {
                // console.log(itemId, status);
                if (schedule?.disabled) {
                  schedule[itemId].disabled = !status;
                } else {
                  schedule[itemId] = {
                    category: itemId,
                    disabled: !status,
                    value: 0,
                  };
                }
              },
              itemCategory: (item) => {
                setCategoryItem(item);
              },
            },
          }),
          //title + button
          {
            component: "container",
            style: {
              display: "flex",
              width: "100%",
              height: "auto",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            },
            children: [
              //title de horários
              {
                component: "container",
                style: {
                  display: "flex",
                  height: "auto",
                  width: "auto",
                  flexDirection: "column",
                },
                children: [
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      gap: 4,
                      alignItems: "center",
                      flexDirection: "row",
                    },
                    children: [
                      {
                        component: "text",
                        text: "Horários - ",
                        style: {
                          fontFamily: "Poppins",
                          fontSize: 24,
                        },
                      },
                      {
                        component: "text",
                        text: categoryItem?.name || "",
                        style: {
                          fontFamily: "Poppins",
                          fontSize: 24,
                        },
                      },
                    ],
                  },
                ],
              },
              //button editar/salvar
              {
                component: "container",
                style: {
                  display: "flex",
                  width: "auto",
                  height: "auto",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: 18,
                },
                children: [
                  //cancelar
                  Button({
                    style: {
                      button: {
                        display: isEdit === true ? "flex" : "none",
                        padding: 8,
                        alignItems: "center",
                        borderRadius: 4,
                        border: "none",
                        backgroundColor: "transparent",
                      },
                      label: {
                        fontSize: 14,
                        color: Theme.colors.red.light,
                        fontFamily: "Poppins",
                      },
                    },
                    label: "cancelar",
                    onPress: () => {
                      PopupShow({
                        description: "",
                        style: {
                          box: {
                            width: "22%",
                            alignItems: "center",
                          },
                          close: {
                            display: "none",
                          },
                        },
                        item: [
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              width: "100%",
                              gap: 12,
                            },
                            children: [
                              {
                                component: "text",
                                text: "Sair sem salvar ?",
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: 18,
                                  color: "#0864F1",
                                },
                              },
                              {
                                component: "text",
                                text: "As alterações não salvas serão descartadas quando você sair.",
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: 14,
                                },
                              },
                            ],
                          },
                        ],
                        buttons: [
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "row",
                              gap: 8,
                              marginTop: 12,
                            },
                            children: [
                              //btn fechar
                              Button({
                                label: "Sair",
                                style: {
                                  button: {
                                    backgroundColor: "#E4EEFF",
                                    width: "100%",
                                    alignItems: "center",
                                    height: "42%",
                                    borderRadius: 6,
                                    border: "none",
                                  },
                                  label: {
                                    fontFamily: Theme.fontFamilies.primary,
                                    fontSize: 18,
                                    color: "#0864F1",
                                  },
                                },
                                onPress: async () => {
                                  if (isEdit === true) {
                                    setIsEdit(!isEdit);
                                    PopupClose();
                                  } else {
                                    PopupClose();
                                    setIsEdit(!isEdit);
                                  }
                                },
                              }),
                              //btn salvar
                              Button({
                                label: "Salvar",
                                style: {
                                  button: {
                                    backgroundColor: "#0864F1",
                                    width: "100%",
                                    alignItems: "center",
                                    height: "42%",
                                    borderRadius: 6,
                                    border: "none",
                                  },
                                  label: {
                                    fontFamily: Theme.fontFamilies.primary,
                                    fontSize: 18,
                                    color: "white",
                                  },
                                },
                                onPress: () => {
                                  if (isEdit === true) {
                                    // const { data } =
                                    //   api.utils.category_promotion(
                                    //     Object.values(promotion)
                                    //   );
                                    // console.log("verPromotionSend", promotion);
                                    // Set({
                                    //   ...Data,
                                    //   user: {
                                    //     ...Data.user,
                                    //     promotion: Object.values(promotion),
                                    //   },
                                    // });
                                    PopupClose();
                                    setIsEdit(!isEdit);
                                  } else {
                                    PopupClose();
                                    setIsEdit(!isEdit);
                                  }
                                },
                              }),
                            ],
                          },
                        ],
                      });
                    },
                  }),
                  //salvar
                  Button({
                    label: isEdit === true ? "Salvar" : "Editar",
                    style: {
                      button: {
                        padding: 8,
                        paddingLeft: 16,
                        paddingRight: 16,
                        alignItems: "center",
                        borderRadius: 4,
                        border: "none",
                        backgroundColor: "blue",
                      },
                      label: {
                        color: "white",
                      },
                    },
                    onPress: () => {
                      if (isEdit === true) {
                        const { data } = api.provider.categorySchedule(
                          Object.values(schedule)
                        );
                        console.log("verPromotionSend", schedule);
                        Set({
                          ...Data,
                          user: {
                            ...Data.user,
                            schedule: Object.values(schedule),
                          },
                        });
                        setIsEdit(!isEdit);
                      } else {
                        setIsEdit(!isEdit);
                      }
                    },
                  }),
                ],
              },
            ],
          },
          //content horários
          ScheduleList({
            idCategory: categoryItem,
            scheduleList: weekHours,
          }),
        ],
      },
    ],
  };
};
