import Theme from "theme";
import React, { useState, useEffect } from "react";

import { ShowPage } from "context/showPage";

import Switch from "components/web/switch";
import InputNumber from "components/web/inputNumber";
import Button from "components/web/button";

import { Context } from "context/data";

import api from "service";

export default (props) => {
  const { PageInfo } = ShowPage();
  const [category, setCategory] = useState({});

  const { Data, Set } = Context();

  let edit = props?.edit;

  useEffect(async () => {
    let { data } = await api.utils.service_category();

    let userCategory = {};
    data?.data?.map((c) => {
      c.disabled = props?.category[c._id]?.disabled ?? true;
      c.value = props?.category[c._id]?.value ?? 0;
      userCategory[c._id] = c;
    });
    setCategory(userCategory);
  }, [props.category]);

  return {
    component: "container",
    style: {
      display: "flex",
      width: "100%",
      height: "100%",
      gap: 24,
    },
    children: [
      //item
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          flexDirection: "column",
          ...props?.style?.box,
        },
        children: Object.values(category).map((item, index) => {
          if (item?.code !== 4)
            return {
              component: "container",
              style: {
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "flex-start",
                height: 88,
                gap: 24,
                flexDirection: "row",
                borderBottom: "1px solid #ECECEC",
              },
              children: [
                Switch({
                  style: {
                    display: edit === true ? "flex" : "none",
                  },
                  checked: !category[item._id]?.disabled,
                  value: category[item._id]?.disabled,
                  onChange: async () => {
                    setCategory({
                      ...category,
                      [item._id]: {
                        ...category[item._id],
                        disabled: !category[item._id]?.disabled,
                      },
                    });
                    if (props?.onChange?.switch) {
                      props.onChange.switch(
                        item._id,
                        !category[item._id]?.disabled
                      );
                    }
                  },
                }),
                //icon
                {
                  component: "image",
                  src: item?.image,
                  style: {
                    display: "flex",
                    width: 48,
                    height: 48,
                    aspectRatio: "1/1",
                  },
                },
                //titleContent
                {
                  component: "text",
                  text: item?.name,
                  style: {
                    fontSize: 16,
                    width: "14%",
                    color:
                      category[item._id]?.disabled === false
                        ? "black"
                        : "gray" && category[item._id]?.value > 0
                        ? "black"
                        : "gray",
                    fontFamily: "Poppins",
                    marginBottom: 8,
                  },
                },
                InputNumber({
                  style: {
                    display: edit === true ? "flex" : "none",
                  },
                  min: 0,
                  max: 100,
                  disabled: category[item._id].disabled,
                  onChange: async (value) => {
                    setCategory({
                      ...category,
                      [item._id]: {
                        ...category[item._id],
                        value: value,
                      },
                    });
                    if (props?.onChange?.inputValue) {
                      props.onChange.inputValue(
                        item._id,
                        value,
                        category[item._id].disabled
                      );
                    }
                  },
                  value: category[item._id]?.value,
                  addonAfter: "%",
                  step: "1",
                }),
                //textPromotion
                {
                  component: "container",
                  style: {
                    display: edit === true ? "none" : "flex",
                    flexDirection: "row",
                    width: "auto",
                    height: "auto",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 8,
                    gap: 4,
                    borderRadius: 8,
                    backgroundColor:
                      category[item._id]?.value === 0
                        ? "rgba(205, 205, 205, 0.5)"
                        : "rgba(228, 238, 255, 1)",
                  },
                  children: [
                    {
                      component: "text",
                      text: category[item._id]?.value || "",
                      style: {
                        display:
                          category[item._id]?.value > 0 ? "flex" : "none",
                        fontSize: 16,
                        fontFamily: "Poppins",
                      },
                    },
                    {
                      component: "text",
                      text:
                        category[item._id]?.value > 0
                          ? "% em todos os serviços"
                          : "Sem promoção configurada",
                      style: {
                        fontSize: 16,
                        fontFamily: "Poppins",
                      },
                    },
                  ],
                },
              ],
            };
        }),
      },
    ],
  };
};
