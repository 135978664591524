import Theme from "theme";
import React, { useState, useEffect } from "react";

import { ShowPage } from "context/showPage";

import Switch from "components/web/switch";
import InputNumber from "components/web/inputNumber";
import Button from "components/web/button";

import api from "service";

export default (props) => {
  const { PageInfo } = ShowPage();
  const [category, setCategory] = useState({});

  const [itemSelected, setItemSelected] = useState([]);

  let edit = props?.edit;

  useEffect(async () => {
    let { data } = await api.utils.service_category();
    let userCategory = {};
    data?.data?.map((c) => {
      // console.log("verDados", c, props?.category);
      c.disabled = props?.category[c._id]?.disabled ?? true;
      // c.offHours = props?.category[c._id]?.offHours;
      userCategory[c._id] = c;
    });
    setCategory(userCategory);
    console.log("verCategory", userCategory);
  }, [props.category]);

  useEffect(async () => {
    setItemSelected([]);
  }, [PageInfo.show]);

  return {
    component: "container",
    style: {
      display: "flex",
      width: "100%",
      height: "auto",
    },
    children: [
      //item
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          height: "auto",
          alignItems: "center",
          justifyContent: "center",
          gap: 24,
          flexDirection: "row",
          ...props?.style?.box,
        },
        children: Object.values(category).map((item, index) => {
          if (item?.code !== 4)
            return {
              component: "container",
              style: {
                display: "flex",
                width: "40%",
                height: "100%",
                alignItems: "center",
                justifyContent: "flex-start",
                borderRadius: 8,
                flexDirection: "column",
                cursor: "pointer",
                border: itemSelected.includes(item?._id)
                  ? "1px solid #0864F1"
                  : "1px solid #ECECEC",
                ...props?.style?.itemCategory,
              },
              onClick: () => {
                setItemSelected([item?._id]);
                if (props?.onChange?.itemCategory) {
                  props.onChange.itemCategory(item);
                }
              },
              children: [
                //icon + title
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    flexDirection: "row",
                    padding: 8,
                    gap: 4,
                  },
                  children: [
                    //icon
                    {
                      component: "image",
                      src: item?.image,
                      style: {
                        display: "flex",
                        width: 32,
                        height: 32,
                        aspectRatio: "1/1",
                      },
                    },
                    //titleContent
                    {
                      component: "text",
                      text: item?.name,
                      style: {
                        fontSize: 14,
                        marginTop: 8,
                        width: "100%",
                        color:
                          category[item._id]?.disabled === false
                            ? "black"
                            : "gray",
                        fontFamily: "Poppins",
                        marginBottom: 8,
                      },
                    },
                  ],
                },
                //switch
                {
                  component: "container",
                  style: {
                    display: "flex",
                    width: "100%",
                    minHeight: 24,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                    backgroundColor:
                      category[item._id]?.disabled === true
                        ? "rgba(205, 205, 205, 0.5)"
                        : "rgba(228, 238, 255, 1)",
                  },
                  children: [
                    Switch({
                      style: {
                        display: edit === true ? "flex" : "none",
                        height: 24,
                      },
                      checked: !category[item._id]?.disabled,
                      value: category[item._id]?.disabled,
                      onChange: async () => {
                        setCategory({
                          ...category,
                          [item._id]: {
                            ...category[item._id],
                            disabled: !category[item._id]?.disabled,
                          },
                        });
                        if (props?.onChange?.switch) {
                          props.onChange.switch(
                            item._id,
                            !category[item._id]?.disabled
                          );
                        }
                      },
                    }),
                    //text inativo
                    {
                      component: "text",
                      text:
                        category[item._id]?.disabled === true
                          ? "Inativo"
                          : "Ativo",
                      style: {
                        fontFamily: "Poppins",
                        fontSize: 12,
                      },
                    },
                  ],
                },
              ],
            };
        }),
      },
    ],
  };
};
