import Theme from "theme";
import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import { Howl, Howler } from "howler";

import { ShowPage } from "context/showPage";
import { Context } from "context/data";

// import { ShowPopup } from "context/popup";

//api
import api from "service";

//components
import Input from "components/web/input";
import Button from "components/web/button";

//images
import Logo from "assets/images/logo.png";
import DogWoman from "assets/images/iconDogEpeople.svg";
import ShieldCheck from "assets/images/ShieldCheck.svg";

//sounds
import DogAudio from "assets/sounds/dog.mp3";

export default ({ content, setContent }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // const { PopupShow, PopupClose, PopupTime } = ShowPopup();

  const { PageInfo, GoPage, GoInitPage } = ShowPage();

  const { Data, Set } = Context();

  let soundPlay = async (src, time, interval) => {
    const sound = new Howl({
      src,
      html5: true,
      volume: 0.5,
      loop: false,
    });
    for (let i = 0; i < time; i++) {
      await sleep((interval || 550) * i);
      sound.play();
    }
  };

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const inputStyle = {
    input: {
      width: "100%",
      height: 44,
      border: "1px solid #bfbfbf",
      borderBottom: "1px solid #bfbfbf",
      borderRadius: 8,
      padding: 4,
      fontSize: 18,
    },
    title: {
      fontFamily: "Poppins",
      fontSize: 14,
      color: "rgba(52, 64, 84, 1)",
    },
  };

  const inputUsername = Input({
    title: "Usuário",
    value: username,
    setValue: setUsername,
    style: {
      ...inputStyle,
    },
  });

  const inputPassword = Input({
    title: "Senha",
    type: "password",
    value: password,
    setValue: setPassword,
    style: {
      ...inputStyle,
    },
  });

  return {
    render: (
      <Fade>
        {Render({
          component: "container",
          style: {
            display: content === "Login" ? "flex" : "none",
            backgroundColor: "white",
            width: "28vw",
            height: "auto",
            marginTop: 32,
            borderRadius: 4,
            padding: "4%",
            border: "1px solid #E6E6E6",
            flexDirection: "column",
            gap: 28,
          },
          children: [
            //titleForm
            {
              component: "text",
              text: "Login",
              style: {
                width: "100%",
                fontFamily: "Poppins",
                textAlign: "flex-start",
                fontWeight: "600",
                fontSize: 24,
                color: "#05332F",
              },
            },
            //inputs (pensar em um children.map de inputs)
            {
              component: "container",
              style: {
                display: "flex",
                width: "100%",
                height: "auto",
                flexDirection: "column",
                gap: 12,
              },
              children: [inputUsername, inputPassword],
            },
            //Recuperar senha
            {
              component: "text",
              text: "Esqueci minha senha",
              style: {
                fontSize: 14,
                width: "50%",
                fontFamily: "Poppins",
                cursor: "pointer",
                color: "rgba(0, 166, 96, 1)",
              },
              onClick: () => {
                setContent("Recovery");
              },
            },
            //Botão enviar
            {
              component: "container",
              style: {
                display: "flex",
                width: "auto",
                height: "auto",
              },
              children: [
                Button({
                  label: "Entrar",
                  style: {
                    button: {
                      backgroundColor: "white",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 44,
                      backgroundColor: "rgba(0, 166, 96, 1)",
                      borderRadius: 6,
                      border: "none",
                    },
                    label: {
                      fontFamily: Theme.fontFamilies.primary,
                      fontSize: 18,
                      color: "White",
                    },
                  },
                  onPress: async () => {
                    const { data } = await api.auth.login({
                      login_code: username,
                      login_password: password,
                    });
                    await sleep(500);
                    if (data?.status === true) {
                      // salvar token no localstorage
                      await localStorage.setItem("token", data.data.token);
                      soundPlay(DogAudio, 1);
                      await sleep(500);
                      Set({
                        ...Data,
                        user: data.data.user,
                      });
                      GoInitPage();
                    } else {
                      soundPlay(DogAudio, 2);
                      // PopupShow({
                      //   PopupTime: "time",
                      //   type: "time",
                      //   title: "Campos não preenchidos",
                      //   description: "",
                      //   style: {
                      //     close: {
                      //       display: "none",
                      //     },
                      //   },
                      //   cancelText: "",
                      //   confirmText: "",
                      //   onCancel: async () => {},
                      //   onConfirm: async () => {},
                      // });
                    }
                  },
                }),
              ],
            },
          ],
        })}
      </Fade>
    ),
  };
};
