import { Render } from "@8_dev/sjson-web";
import { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { ShowPage } from "context/showPage";
import Theme from "theme";

import ArrowBack from "assets/images/menu/arrowBack.svg";

export default (props) => {
  const { GoBack } = ShowPage();

  useEffect(async () => {}, []);

  // passa props o search caso tenha campo de busca na pagina ou cateudo da pagina..
  // colocar um algo para omitir os dados filtrados

  return {
    render: (
      <div style={{}}>
        <Fade top>
          {Render({
            component: "container",
            style: {
              width: props.width || "80vw",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginLeft: "1%",
            },
            children: [
              // Icon Title
              {
                component: "container",
                style: {
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  minWidth: "100%",
                  gap: "1%",
                },
                children: [
                  // Icon +
                  {
                    component: "container",
                    style: {
                      display: props?.src ? "flex" : "none",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    },
                    onClick: () => {
                      GoBack();
                    },
                    children: [
                      {
                        component: "image",
                        src: props?.src || " ",
                        style: {
                          padding: "1%",
                        },
                      },
                    ],
                  },
                  //div
                  {
                    component: "container",
                    style: {
                      width: 8,
                      height: 64,
                      backgroundColor: "#05332F",
                      borderRadius: "2px",
                    },
                  },
                  //titlePage
                  {
                    component: "text",
                    text: props?.title || "  ",
                    style: {
                      fontFamily: "Poppins",
                      fontSize: 28,
                      flex: 1,
                      width: "100%",
                    },
                  },
                ],
              },
            ],
          })}
        </Fade>
      </div>
    ),
  };
};
